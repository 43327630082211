<template>
  <div class="candidates">
    <div class="vx-row">
      <div class="vx-col md:w-1/4 w-ful select-position">
        <label>Seleccione cargo</label>
        <v-select v-model="charge" :options="charges"></v-select>                              
      </div>
      <div class="vx-col md:w-1/5 w-full">
        <br>
        <vs-button @click="popupActive2=true" color="primary" type="filled">Crear cargo</vs-button>
      </div>    
    </div>
    <vs-popup classContent="popup-example" title="Crear proceso" :active.sync="popupSelectProjectType">
      <vs-row>
        <vs-col vs-offset="2" vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">
          <div class="vx-card p-8">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div class="vx-card__body">
                <div class="text-center">
                  <h4>{{candidateForCreateProject.first_name}} {{candidateForCreateProject.last_name}}</h4>
                  <p class="text-grey">{{candidateForCreateProject.email}}</p>
                  <p class="text-grey">{{candidateForCreateProject.phone}}</p>
                  <br>
                  <div class="list-create-project">
                    <label for="">Por favor selecciones un tipo de proceso</label>
                    <v-select v-model="project_type" :options="project_types"></v-select>
                  </div>
                  <vs-row v-if="project_type != -1">
                    <vs-col vs-offset="7" vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
                      <vs-button color="primary" type="filled" @click="createProcess(candidateForCreateProject)">Crear proceso</vs-button>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
            </div>
          </div>          
        </vs-col>
      </vs-row>
    </vs-popup>    
    <vs-popup background-color="rgba(255,255,255,.6)"  class=""  title="Proceso" :active.sync="popupCreate">
      <div class="vx-row row-fields">              
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('first_name'))">Nombre*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Nombre completo" name="first_name" v-model="form.first_name" />          
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('last_name'))">Apellidos*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Apellidos" name="last_name" v-model="form.last_name" />          
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('email'))">Email*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Email" name="email" v-model="form.email" />          
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('phone'))">Teléfono*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Teléfono" name="phone" v-model="form.phone" />          
        </div>
      </div>

      <div class="vx-row row-fields">              
        <div class="vx-col md:w-1/2 w-full">
          <label>Seleccione cargo</label>
          <v-select v-model="form.position_id" :options="charges"></v-select>                              
        </div>
      </div>      
      <!-- button sumit -->
      <vs-row class="row-btn-save">
        <vs-col vs-offset="10"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">Guardar</vs-button>
        </vs-col>
      </vs-row>

      <vs-popup title="Crear cargo" :active.sync="popupActive2">
        <div class="vx-row row-fields">     
          <div class="vx-col md:w-1/2 w-full">
            <label>Seleccione cargo</label>
            <v-select v-model="formPosition.position_type_id" :options="position_types"></v-select>                              
          </div>                           
          <div class="vx-col md:w-1/2 w-full">
            <label>Nombre*</label>
            <vs-input size="large" placeholder="Nombre" name="formPositionName" v-model="formPosition.name" />          
          </div>
          <!-- <div class="vx-col md:w-1/2 w-full">
            <label>Descripción*</label>
            <vs-input size="large" placeholder="Descripción" name="formPositionDescription" v-model="formPosition.description" />          
          </div> -->
        </div>
        <vs-row class="row-btn-save">
          <vs-col vs-offset="10"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
            <vs-button type="filled" @click.prevent="submitFormCreatePosition" class="mt-5 block">Guardar</vs-button>
          </vs-col>
        </vs-row>        
      </vs-popup>      

   
      <vs-popup title="Candidato" class="cv-popup test" :active.sync="popupShow">

        <div class="pdf-content">  

          <div ref="content" class="cv">
          <div class="cv-header wrapper">
            <div class="box"></div>
            <div class="title box">
              <div class="logo-title">
                  <h1>Globalwork</h1>
              </div>
              <div class="name-title">
                <h3>{{candidate.first_name}} {{candidate.last_name}} </h3> 
              </div>
              <div class="place-title">
                <h5> Medellín, Colombia </h5>
              </div>
              <div class="hr-title">
                <hr>
              </div>
              
              <div class="phone-title">
                  <h6>{{candidate.phone}} (Mobile)</h6>
              </div>

              <div class="email-title">
                  <h6>{{candidate.email}}</h6>
              </div>
            </div>
            <div class="box"></div>
          </div>

          <br><br>
          <div class="contenido wrapper">
            <div class="column-one">

              <h4>Referencias familiares</h4>
              <br>
              <ul>
                <li v-for="item in candidate.family_references" :key="item.id">
                  {{ item.family_reference_full_name }} ({{ item.family_reference_telephone }}) <br>
                  {{ item.family_reference_email }}
                  <br><br>
                </li>
              </ul>
              <br>

              <h4>Referencias personales</h4>
              <br>
              <ul>
                <li v-for="item in candidate.personal_references" :key="item.id">
                  {{ item.personal_reference_name }} ({{ item.personal_reference_telephone }}) <br>
                  {{ item.personal_reference_email }}
                  <br><br>
                </li>
              </ul>
              <br>

              <h4>Estudios básicos</h4>
              <br>
              <ul>
                <li v-for="item in candidate.academic_references" :key="item.id">
                  <div v-if="item.academic_type == 0">
                    <b>{{ item.name_institution }}</b>
                    {{ item.name_title }}, {{item.date_graduation}}
                    <br><br>
                  </div>

                </li>
              </ul>  
            </div>
            <div class="column-two"> <hr></div>
            <div class="column-three">
              <h4>Estudios superiores</h4>
              <br>
              <ul>
                <li v-for="item in candidate.academic_references" :key="item.id">
                  <div v-if="item.academic_type == 1">
                    <b>{{ item.name_institution }}</b>
                    {{ item.name_title }}, {{item.date_graduation}}
                    <br><br>
                  </div>

                </li>
              </ul>                
              <h4>Referencias laborales</h4>
              <br>
              <ul>
                <li v-for="item in candidate.laboral_references" :key="item.id">
                  <b>{{ item.company_name }}</b>
                  {{ item.charge }} <br>
                  <b>{{ item.contact_name }}</b>
                  {{ item.contact_telephone }}<br>
                  {{ item.contact_telephone }}<br>
                  {{ item.email }}
                  <br><br>
                </li>
              </ul>
              <br>
            </div>
          
          </div>

          <div id="pdf"></div>

        </div> 


        </div>  
                
        <div class="download">
          <vs-button color="primary" type="filled">Descargar PDF</vs-button>
        </div>  

  
      </vs-popup>      
    </vs-popup>

    <vs-row>
      <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-w="4" class="table-trello">
        <vs-list class="list-trello">
          <vs-list-header title="Aplicación" color="primary"></vs-list-header>
          <draggable :list="application" group="people" class="p-2 cursor-move" @add="onAddapplication">
            <vs-list-item v-for="(listItem, index) in application" :key="index">

              <vs-row vs-justify="center" >
                <vs-col type="flex"  vs-justify="center" vs-align="center" vs-w="12">
                  <vs-card class="card-trello">
                    <div slot="header">
                      <h5>
                        {{listItem.first_name + ' ' + listItem.last_name}}
                      </h5>
                    </div>
                    <div>
                      <span>
                       Teléfono: {{listItem.phone}} <br>
                       Correo: {{listItem.email}} 
                      </span>
                    </div>
                    <vs-row vs-justify="flex-end">
                      <vs-button color="primary" @click="openModalShowCandidate(listItem)" icon="remove_red_eye"></vs-button>
                    </vs-row>
                  </vs-card>
                </vs-col>
              </vs-row>

            </vs-list-item>
          </draggable>
        </vs-list>        
      </vs-col>
      <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-w="4" class="table-trello">
        <vs-list class="list-trello">
          
          <vs-list-header title="Entrevista" color="primary"></vs-list-header>
          <draggable :list="interview" group="people" class="p-2 cursor-move" @add="onAddinterview">
            <vs-list-item v-for="(listItem, index) in interview" :key="index">

              <vs-row vs-justify="center" >
                <vs-col type="flex"  vs-justify="center" vs-align="center" vs-w="12">
                  <vs-card class="card-trello">
                    <div slot="header">
                      <h5>
                        {{listItem.first_name + ' ' + listItem.last_name}}
                      </h5>
                    </div>
                    <div>
                      <span>
                       Teléfono: {{listItem.phone}} <br>
                       Correo: {{listItem.email}} 
                      </span>
                    </div>
                    <vs-row vs-justify="flex-end">
                      <vs-button color="primary" @click="openModalShowCandidate(listItem)" icon="remove_red_eye"></vs-button>
                    </vs-row>
                  </vs-card>
                </vs-col>
              </vs-row>


               
            </vs-list-item>

            
          </draggable>
        </vs-list>            
      </vs-col>
      <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-w="4" class="table-trello">
        <vs-list class="list-trello">
          <vs-list-header title="Preseleccionados" color="primary"></vs-list-header>
          <draggable :list="preselected" group="people" class="p-2 cursor-move" @add="onAddpreselected">
            <vs-list-item v-for="(listItem, index) in preselected" :key="index">

              <vs-row vs-justify="center">
                <vs-col type="flex"  vs-justify="center" vs-align="center" vs-w="12">
                  <vs-card class="card-trello">
                    <div slot="header">
                      <h5>
                        {{listItem.first_name + ' ' + listItem.last_name}}
                      </h5>
                    </div>
                    <div>
                      <span>
                       Teléfono: {{listItem.phone}} <br>
                       Correo: {{listItem.email}} <br>
                       <br>
                       <vs-checkbox v-model="listItem.generate_process" @click="openSelectProjectType(listItem)" >Crear proceso</vs-checkbox>

                      </span>
                    </div>
                   
                    <vs-row vs-justify="flex-end">
                      <vs-button color="primary" @click="openModalShowCandidate(listItem)" icon="remove_red_eye"></vs-button>
                    </vs-row>
                  </vs-card>
                </vs-col>
              </vs-row>   
                           
            </vs-list-item>
          </draggable>
        </vs-list>                    
      </vs-col>            
    </vs-row>
    <vs-col vs-offset="10"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
      <vs-button type="filled" @click="openModalCreateCandidate" class="mt-5 block">Crear candidato</vs-button>
    </vs-col>    
  </div>
</template>

<script>

import vSelect from 'vue-select';
import draggable from 'vuedraggable'
import api from '../../../mixins/api.js'

import jsPDF from 'jspdf' 
import { mapGetters } from 'vuex'
export default {
  mixins: [api],
  data(){
    return{
      charge: {},
      charges: [],
      position_types: [],
      createProcessList: [],
      default: true,              
      popupCreate: false,
      popupActive2: false,
      popupShow: false,
      popupSelectProjectType: false,
      checkProjectType: -1,
      candidateForCreateProject: {},
      project_type: '',
      candidate: '',
      projectTypesSpanish:[
        "Visita Domiciliaria",
        "Prueba psicotécnica",
        "Estudio de seguridad",
        "Prueba de poligrafía",
        "Listados de riesgo",
        "Antecedentes Judiciales",
        "Visita Domiciliaria Basica",
        "Academica y Antecedentes",
        "Verificacion Academica",
        "Referencias, Academicas y Laborales",
        "Verificación Laboral",
        "Verificación Academica y Laboral",
        "Visita basica, antecedentes y datacredito"
      ],
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        client_id: '',
        position_id: ''
      },
      formPosition: {
        position_type_id: '',
        name: '',
        description: '',
        client_id: ''
      },
      application: [

      ],
      interview: [

      ],
      preselected: [

      ],
      project_types: [

      ]
    }
  },
  methods: {
    openSelectProjectType(candidate){
      this.candidateForCreateProject = candidate
      if(candidate.generate_process){
        candidate.generate_process = false
        this.deleteProcess(candidate)        
      }else{
        this.popupSelectProjectType = true        
      }      
    },
    download() {
      const doc = new jsPDF();
      const contentHtml = this.$refs.content.innerHTML;
      doc.fromHTML(contentHtml, 15, 15, {
        width: 170
      });
      doc.save("sample.pdf");
    },
    // downloadWithCSS() {
    //     var doc = new jsPDF();
    //     html2canvas(this.$refs.content,{ })
    //     .then(function (canvas) {
    //       document.getElementById('pdf').appendChild(canvas)
    //       const img = canvas.toDataURL('image/jpeg', 1.0);
    //       doc.addImage(img,'JPEG', -0.5,0)
    //       doc.save("certificado.pdf");
    //       document.getElementById('pdf').innerHTML = ''

    //     });      
    // },
    // createProcess(candidate){
    //   console.log("**candidate** ", JSON.stringify(candidate))
    //   var path = "/api/v1/candidates/"+candidate.id
    //   var message = ""
    //   if(candidate.generate_process){
    //     candidate.generate_process = false
    //     this.deleteProcess(candidate)        
    //   }else{
    //     this.popupSelectProjectType = true        
    //   }      
    // },
    deleteProcess(candidate){
      var path = "/api/v1/candidates/"+candidate.id
      var message = "Se eliminó el proceso"
      this.udpateCandidate(path, message, candidate)
    },
    createProcess(candidate){
      var path = "/api/v1/candidates/"+candidate.id
      var message = "El proceso se creo correctamente"
      candidate.generate_process = true
      candidate.project_type = this.project_type.id
      this.udpateCandidate(path, message, candidate)
      this.candidateForCreateProject = {}
      this.popupSelectProjectType = false        
      this.project_type = null      
    },
    udpateCandidate(path, message, candidate){
      console.log("udpateCandidate ", JSON.stringify(candidate))
      this.requestPut(this.url()+path, candidate, this.headers()).then(response => {
        this.loadCandidates(this.charge.id)
        console.log("response update candidate ", response);
        this.$vs.notify({color:'success',title:'Proceso creado',text: message, position: 'bottom-left'})      
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
        this.percentajeForm = 100
        this.$vs.notify({color:'danger',title:'Ups',text:'No se creo el proceso', position: 'bottom-left'})      
      })      
    },
    openModalCreateCandidate(){
      this.popupCreate = true;
    },
    openModalShowCandidate(candidate){
      this.popupShow = true;

      var path = `/api/v1/candidates/${candidate.id}`
      
      this.requestGetWithoutContent(this.url()+ path, candidate, {}).then(response => {
        console.log(response)
        this.candidate = response.data;
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
      })
    },
    colorLabel(errorLabel){
      var cadenaColor = 'color: black';
      if(errorLabel){
        cadenaColor = 'color: red';
      }
      return cadenaColor;
    }, 
    restoreFormPosition(){
      this.formPosition = {
        name: '',
        description: '',
        client_id: ''
      }
    },    
    submitFormCreatePosition(){
      var client_id = this.userInfo.user.id.client_id          
      this.formPosition.client_id = client_id
      var position_type_id_tmp = this.formPosition.position_type_id
      this.formPosition.position_type_id = position_type_id_tmp.id
      var path = "/api/v1/positions"
      this.requestPost(this.url()+path, this.formPosition).then(response => {
        console.log("response submitFormCreatePosition ", JSON.stringify(response))
        this.loadPositions(1)
        this.popupActive2 = false;
        this.$vs.notify({color:'success',title:'Cargo creado',text:'Cargo creado correctamente', position: 'bottom-left'})
        this.formPosition.position_type_id = position_type_id_tmp
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
        this.percentajeForm = 100
        this.$vs.notify({color:'danger',title:'Ups',text:'No se creo el cargo', position: 'bottom-left'})      
        this.formPosition.position_type_id = position_type_id_tmp
      })
    },
    restoreFormCandidate(){
      this.form = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        client_id: '',
        position_id: ''
      }
    },
    submitForm() {
      var client_id = this.userInfo.user.id.client_id          
      this.form.client_id = client_id
      var position = this.form.position_id
      this.form.position_id = position.id      
      this.$validator.validateAll().then(result => {
        if(result) {
          console.log("this.form ", JSON.stringify(this.form))
          var path = "/api/v1/candidates"
          this.requestPost(this.url()+path, this.form).then(response => {
            console.log("response " + path, response)
            this.$vs.notify({color:'success',title:'Candidato creado',text:'candidato creado correctamente', position: 'bottom-left'})      
            this.popupCreate = false;
            this.charge = position
            this.loadCandidates(position.id)
            this.restoreFormCandidate()            
          }, (err) => {
            console.log('err users -> ' +  JSON.stringify(err))
            this.$vs.notify({color:'danger',title:'Ups',text:'No se creo el candidato', position: 'bottom-left'})      
          })
        }else{
          // form have errors
        }
      })
    },
    sendCandidateToUpdate(candidate, newStatus){
      console.log("**sendCandidateToUpdate**")
      console.log(candidate)
      candidate.status = newStatus
      var candidateId = candidate.id
      var path = "api/v1/candidates/" + candidateId
      this.requestPut(this.url()+path, candidate, this.headers()).then(response => {
        console.log("response " + path, JSON.stringify(response))
        var candidateUpdate = response.data
        var index = 0
        var indexCandidate = 0
        console.log("candidateUpdate ", JSON.stringify(candidateUpdate))
        if(newStatus == 0){
          this.application.forEach(currentItem => {
            if(candidateUpdate.id == currentItem.id){
              indexCandidate = index
            }
            index++
          })
          this.application[indexCandidate] = candidateUpdate
        }
        if(newStatus == 1){
          this.interview.forEach(currentItem => {
            if(candidateUpdate.id == currentItem.id){
              indexCandidate = index
            }
            index++
          })
          this.interview[indexCandidate] = candidateUpdate          
        }
        if(newStatus == 2){
          this.preselected.forEach(currentItem => {
            if(candidateUpdate.id == currentItem.id){
              indexCandidate = index
            }
            index++
          })
          this.preselected[indexCandidate] = candidateUpdate          
        }         
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
        this.percentajeForm = 100
      })
    },
    onAddapplication(evt){
      console.log("**onAddapplication**")      
      var index = evt.newIndex
      var candidate = this.application[index]
      console.log("index ", index)
      console.log("candidate ", candidate)
      this.sendCandidateToUpdate(candidate, 0)
    },
    onAddinterview(evt){
      console.log("**e**")      
      var index = evt.newIndex
      var candidate = this.interview[index]
      console.log("index ", index)
      console.log("candidate ", candidate)
      this.sendCandidateToUpdate(candidate, 1)
    },
    onAddpreselected(evt){
      console.log("**onAddpreselected**")      
      var index = evt.newIndex
      var candidate = this.preselected[index]
      console.log("index ", index)
      console.log("candidate ", candidate)
      this.sendCandidateToUpdate(candidate, 2)
    },
    loadPositionTypes(){
      console.log("PositionTypes **************")
      var path = "api/v1/position_types";
      var url = this.url()+path      
      console.log('url PositionTypes ', url)
      this.requestGetWithoutContent(url, {}).then(response => {        
        console.log("response PositionTypes", JSON.stringify(response))
        if(response.status == 200){
          console.log("response.data")
          console.log(response.data)
          this.position_types = response.data.position_types
        }else{
          console.log("error -> " + JSON.stringify(response));          
        }
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
      })         
    },
    loadPositions(origin){
      var clientId = this.userInfo.user.id.client_id
      var path = "api/v1/positions/by_client?client_id="+clientId;
      var url = this.url()+path      
      console.log('url ', url)
      this.requestGetWithoutContent(url, {}).then(response => {
        if(response.status == 200){
          this.charges = response.data.charges
          if(origin == 1){
            console.log("Entra a 1", JSON.stringify(this.charges))
            this.charge = this.charges[this.charges.length - 1]
          }
        }else{
          console.log("error -> " + JSON.stringify(response));          
        }
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
      })         
    },
    loadCandidates(position_id){
      var path = '/api/v1/candidates/by_status?position_id=' + position_id
      this.requestGetWithoutContent(this.url()+path, {}).then(response => {
        console.log("response ", JSON.stringify(response))
        if(response.status == 200 ){
          this.application = response.data.application
          this.interview = response.data.interview
          this.preselected = response.data.preselected
        }else{
          console.log("erroror ")  
        }
      }, (err) => {
        console.log("err ", err)
      })
    },
    loadProjectTypes(position_id){
      var path = '/api/v1/clients/project_types'
      this.requestGetWithoutContent(this.url()+path, {}).then(response => {
        if(response.status == 200 ){
          var keys = Object.keys(response.data.project_types)
          keys.forEach(key => {
            this.project_types.push({id: response.data.project_types[key], label: this.projectTypesSpanish[response.data.project_types[key]]})
          });        
        }else{
          console.log("error ", position_id)  
        }
      }, (err) => {
        console.log("err ", err)
      })
    }
  },
  watch: {
    charge: function (val){
      var position_id = val.id
      this.form.position_id = val
      this.loadCandidates(position_id)
    },
    charges: function(val){
      console.log(val)
      if(this.default){
        this.charge = this.charges[0]
        this.default = false
      }      
    }
  },  
  created: function(){
    this.loadPositionTypes()
    this.loadPositions(0)   
    this.loadProjectTypes() 
  },
  computed: {
    ...mapGetters(['userInfo']),
  },      
  components: {
    draggable,
    vSelect
  }

}
</script>

<style>
  
  .table-trello{
    background: #ffffff;
    align-items: flex-start !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .row-fields{
    background: #f7f7f7;
    margin-left: 5rem;
    margin-right: 5rem;
    border-radius: 10px;    
  }
  .vx-row{
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .vs-con-input-label{
    width: 100%;
  }
  .vdp-datepicker input{
    width: 100%;
  }
  .select-position{
    padding-bottom: 1rem;
  }
  .list-trello{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);    
  }
  .card-trello{
    -webkit-box-shadow: 2px 2px 4px 5px #ccc;  
    -moz-box-shadow:    2px 2px 4px 5px #ccc;  
    box-shadow:         2px 2px 4px 5px #ccc;
  }
  .vs-list--slot {
    width: 100%;
  }
  .list-create-project{
    padding-bottom: 15rem;
  }
</style>

<style>
  @import './style.css';
</style>
