var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "candidates" },
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4 w-ful select-position" },
          [
            _c("label", [_vm._v("Seleccione cargo")]),
            _c("v-select", {
              attrs: { options: _vm.charges },
              model: {
                value: _vm.charge,
                callback: function ($$v) {
                  _vm.charge = $$v
                },
                expression: "charge",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full" },
          [
            _c("br"),
            _c(
              "vs-button",
              {
                attrs: { color: "primary", type: "filled" },
                on: {
                  click: function ($event) {
                    _vm.popupActive2 = true
                  },
                },
              },
              [_vm._v("Crear cargo")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "vs-popup",
        {
          attrs: {
            classContent: "popup-example",
            title: "Crear proceso",
            active: _vm.popupSelectProjectType,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupSelectProjectType = $event
            },
          },
        },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-offset": "2",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "8",
                  },
                },
                [
                  _c("div", { staticClass: "vx-card p-8" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-card__collapsible-content vs-con-loading__container",
                      },
                      [
                        _c("div", { staticClass: "vx-card__body" }, [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.candidateForCreateProject.first_name
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.candidateForCreateProject.last_name
                                    )
                                ),
                              ]),
                              _c("p", { staticClass: "text-grey" }, [
                                _vm._v(
                                  _vm._s(_vm.candidateForCreateProject.email)
                                ),
                              ]),
                              _c("p", { staticClass: "text-grey" }, [
                                _vm._v(
                                  _vm._s(_vm.candidateForCreateProject.phone)
                                ),
                              ]),
                              _c("br"),
                              _c(
                                "div",
                                { staticClass: "list-create-project" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(
                                      "Por favor selecciones un tipo de proceso"
                                    ),
                                  ]),
                                  _c("v-select", {
                                    attrs: { options: _vm.project_types },
                                    model: {
                                      value: _vm.project_type,
                                      callback: function ($$v) {
                                        _vm.project_type = $$v
                                      },
                                      expression: "project_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.project_type != -1
                                ? _c(
                                    "vs-row",
                                    [
                                      _c(
                                        "vs-col",
                                        {
                                          attrs: {
                                            "vs-offset": "7",
                                            "vs-type": "flex",
                                            "vs-justify": "center",
                                            "vs-align": "center",
                                            "vs-w": "5",
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                color: "primary",
                                                type: "filled",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.createProcess(
                                                    _vm.candidateForCreateProject
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Crear proceso")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            "background-color": "rgba(255,255,255,.6)",
            title: "Proceso",
            active: _vm.popupCreate,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupCreate = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row row-fields" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("first_name")) },
                  [_vm._v("Nombre*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Nombre completo",
                    name: "first_name",
                  },
                  model: {
                    value: _vm.form.first_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "first_name", $$v)
                    },
                    expression: "form.first_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("last_name")) },
                  [_vm._v("Apellidos*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Apellidos",
                    name: "last_name",
                  },
                  model: {
                    value: _vm.form.last_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "last_name", $$v)
                    },
                    expression: "form.last_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("email")) },
                  [_vm._v("Email*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: { size: "large", placeholder: "Email", name: "email" },
                  model: {
                    value: _vm.form.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "email", $$v)
                    },
                    expression: "form.email",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("phone")) },
                  [_vm._v("Teléfono*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Teléfono",
                    name: "phone",
                  },
                  model: {
                    value: _vm.form.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "phone", $$v)
                    },
                    expression: "form.phone",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row row-fields" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", [_vm._v("Seleccione cargo")]),
                _c("v-select", {
                  attrs: { options: _vm.charges },
                  model: {
                    value: _vm.form.position_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "position_id", $$v)
                    },
                    expression: "form.position_id",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "vs-row",
            { staticClass: "row-btn-save" },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-offset": "10",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-5 block",
                      attrs: { type: "filled" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitForm.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Guardar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-popup",
            {
              attrs: { title: "Crear cargo", active: _vm.popupActive2 },
              on: {
                "update:active": function ($event) {
                  _vm.popupActive2 = $event
                },
              },
            },
            [
              _c("div", { staticClass: "vx-row row-fields" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full" },
                  [
                    _c("label", [_vm._v("Seleccione cargo")]),
                    _c("v-select", {
                      attrs: { options: _vm.position_types },
                      model: {
                        value: _vm.formPosition.position_type_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formPosition, "position_type_id", $$v)
                        },
                        expression: "formPosition.position_type_id",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full" },
                  [
                    _c("label", [_vm._v("Nombre*")]),
                    _c("vs-input", {
                      attrs: {
                        size: "large",
                        placeholder: "Nombre",
                        name: "formPositionName",
                      },
                      model: {
                        value: _vm.formPosition.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.formPosition, "name", $$v)
                        },
                        expression: "formPosition.name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "vs-row",
                { staticClass: "row-btn-save" },
                [
                  _c(
                    "vs-col",
                    {
                      attrs: {
                        "vs-offset": "10",
                        "vs-type": "flex",
                        "vs-justify": "center",
                        "vs-align": "center",
                        "vs-w": "2",
                      },
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "mt-5 block",
                          attrs: { type: "filled" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.submitFormCreatePosition.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("Guardar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-popup",
            {
              staticClass: "cv-popup test",
              attrs: { title: "Candidato", active: _vm.popupShow },
              on: {
                "update:active": function ($event) {
                  _vm.popupShow = $event
                },
              },
            },
            [
              _c("div", { staticClass: "pdf-content" }, [
                _c("div", { ref: "content", staticClass: "cv" }, [
                  _c("div", { staticClass: "cv-header wrapper" }, [
                    _c("div", { staticClass: "box" }),
                    _c("div", { staticClass: "title box" }, [
                      _c("div", { staticClass: "logo-title" }, [
                        _c("h1", [_vm._v("Globalwork")]),
                      ]),
                      _c("div", { staticClass: "name-title" }, [
                        _c("h3", [
                          _vm._v(
                            _vm._s(_vm.candidate.first_name) +
                              " " +
                              _vm._s(_vm.candidate.last_name) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "place-title" }, [
                        _c("h5", [_vm._v(" Medellín, Colombia ")]),
                      ]),
                      _c("div", { staticClass: "hr-title" }, [_c("hr")]),
                      _c("div", { staticClass: "phone-title" }, [
                        _c("h6", [
                          _vm._v(_vm._s(_vm.candidate.phone) + " (Mobile)"),
                        ]),
                      ]),
                      _c("div", { staticClass: "email-title" }, [
                        _c("h6", [_vm._v(_vm._s(_vm.candidate.email))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "box" }),
                  ]),
                  _c("br"),
                  _c("br"),
                  _c("div", { staticClass: "contenido wrapper" }, [
                    _c("div", { staticClass: "column-one" }, [
                      _c("h4", [_vm._v("Referencias familiares")]),
                      _c("br"),
                      _c(
                        "ul",
                        _vm._l(
                          _vm.candidate.family_references,
                          function (item) {
                            return _c("li", { key: item.id }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.family_reference_full_name) +
                                  " (" +
                                  _vm._s(item.family_reference_telephone) +
                                  ") "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.family_reference_email) +
                                  "\n                "
                              ),
                              _c("br"),
                              _c("br"),
                            ])
                          }
                        ),
                        0
                      ),
                      _c("br"),
                      _c("h4", [_vm._v("Referencias personales")]),
                      _c("br"),
                      _c(
                        "ul",
                        _vm._l(
                          _vm.candidate.personal_references,
                          function (item) {
                            return _c("li", { key: item.id }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.personal_reference_name) +
                                  " (" +
                                  _vm._s(item.personal_reference_telephone) +
                                  ") "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.personal_reference_email) +
                                  "\n                "
                              ),
                              _c("br"),
                              _c("br"),
                            ])
                          }
                        ),
                        0
                      ),
                      _c("br"),
                      _c("h4", [_vm._v("Estudios básicos")]),
                      _c("br"),
                      _c(
                        "ul",
                        _vm._l(
                          _vm.candidate.academic_references,
                          function (item) {
                            return _c("li", { key: item.id }, [
                              item.academic_type == 0
                                ? _c("div", [
                                    _c("b", [
                                      _vm._v(_vm._s(item.name_institution)),
                                    ]),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.name_title) +
                                        ", " +
                                        _vm._s(item.date_graduation) +
                                        "\n                  "
                                    ),
                                    _c("br"),
                                    _c("br"),
                                  ])
                                : _vm._e(),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "column-two" }, [_c("hr")]),
                    _c("div", { staticClass: "column-three" }, [
                      _c("h4", [_vm._v("Estudios superiores")]),
                      _c("br"),
                      _c(
                        "ul",
                        _vm._l(
                          _vm.candidate.academic_references,
                          function (item) {
                            return _c("li", { key: item.id }, [
                              item.academic_type == 1
                                ? _c("div", [
                                    _c("b", [
                                      _vm._v(_vm._s(item.name_institution)),
                                    ]),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.name_title) +
                                        ", " +
                                        _vm._s(item.date_graduation) +
                                        "\n                  "
                                    ),
                                    _c("br"),
                                    _c("br"),
                                  ])
                                : _vm._e(),
                            ])
                          }
                        ),
                        0
                      ),
                      _c("h4", [_vm._v("Referencias laborales")]),
                      _c("br"),
                      _c(
                        "ul",
                        _vm._l(
                          _vm.candidate.laboral_references,
                          function (item) {
                            return _c("li", { key: item.id }, [
                              _c("b", [_vm._v(_vm._s(item.company_name))]),
                              _vm._v(
                                "\n                " + _vm._s(item.charge) + " "
                              ),
                              _c("br"),
                              _c("b", [_vm._v(_vm._s(item.contact_name))]),
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.contact_telephone)
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.contact_telephone)
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.email) +
                                  "\n                "
                              ),
                              _c("br"),
                              _c("br"),
                            ])
                          }
                        ),
                        0
                      ),
                      _c("br"),
                    ]),
                  ]),
                  _c("div", { attrs: { id: "pdf" } }),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "download" },
                [
                  _c(
                    "vs-button",
                    { attrs: { color: "primary", type: "filled" } },
                    [_vm._v("Descargar PDF")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            {
              staticClass: "table-trello",
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "4",
              },
            },
            [
              _c(
                "vs-list",
                { staticClass: "list-trello" },
                [
                  _c("vs-list-header", {
                    attrs: { title: "Aplicación", color: "primary" },
                  }),
                  _c(
                    "draggable",
                    {
                      staticClass: "p-2 cursor-move",
                      attrs: { list: _vm.application, group: "people" },
                      on: { add: _vm.onAddapplication },
                    },
                    _vm._l(_vm.application, function (listItem, index) {
                      return _c(
                        "vs-list-item",
                        { key: index },
                        [
                          _c(
                            "vs-row",
                            { attrs: { "vs-justify": "center" } },
                            [
                              _c(
                                "vs-col",
                                {
                                  attrs: {
                                    type: "flex",
                                    "vs-justify": "center",
                                    "vs-align": "center",
                                    "vs-w": "12",
                                  },
                                },
                                [
                                  _c(
                                    "vs-card",
                                    { staticClass: "card-trello" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "header" },
                                          slot: "header",
                                        },
                                        [
                                          _c("h5", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  listItem.first_name +
                                                    " " +
                                                    listItem.last_name
                                                ) +
                                                "\n                    "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            "\n                     Teléfono: " +
                                              _vm._s(listItem.phone) +
                                              " "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                     Correo: " +
                                              _vm._s(listItem.email) +
                                              " \n                    "
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "vs-row",
                                        { attrs: { "vs-justify": "flex-end" } },
                                        [
                                          _c("vs-button", {
                                            attrs: {
                                              color: "primary",
                                              icon: "remove_red_eye",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openModalShowCandidate(
                                                  listItem
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-col",
            {
              staticClass: "table-trello",
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "4",
              },
            },
            [
              _c(
                "vs-list",
                { staticClass: "list-trello" },
                [
                  _c("vs-list-header", {
                    attrs: { title: "Entrevista", color: "primary" },
                  }),
                  _c(
                    "draggable",
                    {
                      staticClass: "p-2 cursor-move",
                      attrs: { list: _vm.interview, group: "people" },
                      on: { add: _vm.onAddinterview },
                    },
                    _vm._l(_vm.interview, function (listItem, index) {
                      return _c(
                        "vs-list-item",
                        { key: index },
                        [
                          _c(
                            "vs-row",
                            { attrs: { "vs-justify": "center" } },
                            [
                              _c(
                                "vs-col",
                                {
                                  attrs: {
                                    type: "flex",
                                    "vs-justify": "center",
                                    "vs-align": "center",
                                    "vs-w": "12",
                                  },
                                },
                                [
                                  _c(
                                    "vs-card",
                                    { staticClass: "card-trello" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "header" },
                                          slot: "header",
                                        },
                                        [
                                          _c("h5", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  listItem.first_name +
                                                    " " +
                                                    listItem.last_name
                                                ) +
                                                "\n                    "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            "\n                     Teléfono: " +
                                              _vm._s(listItem.phone) +
                                              " "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                     Correo: " +
                                              _vm._s(listItem.email) +
                                              " \n                    "
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "vs-row",
                                        { attrs: { "vs-justify": "flex-end" } },
                                        [
                                          _c("vs-button", {
                                            attrs: {
                                              color: "primary",
                                              icon: "remove_red_eye",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openModalShowCandidate(
                                                  listItem
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-col",
            {
              staticClass: "table-trello",
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "4",
              },
            },
            [
              _c(
                "vs-list",
                { staticClass: "list-trello" },
                [
                  _c("vs-list-header", {
                    attrs: { title: "Preseleccionados", color: "primary" },
                  }),
                  _c(
                    "draggable",
                    {
                      staticClass: "p-2 cursor-move",
                      attrs: { list: _vm.preselected, group: "people" },
                      on: { add: _vm.onAddpreselected },
                    },
                    _vm._l(_vm.preselected, function (listItem, index) {
                      return _c(
                        "vs-list-item",
                        { key: index },
                        [
                          _c(
                            "vs-row",
                            { attrs: { "vs-justify": "center" } },
                            [
                              _c(
                                "vs-col",
                                {
                                  attrs: {
                                    type: "flex",
                                    "vs-justify": "center",
                                    "vs-align": "center",
                                    "vs-w": "12",
                                  },
                                },
                                [
                                  _c(
                                    "vs-card",
                                    { staticClass: "card-trello" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "header" },
                                          slot: "header",
                                        },
                                        [
                                          _c("h5", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  listItem.first_name +
                                                    " " +
                                                    listItem.last_name
                                                ) +
                                                "\n                    "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("div", [
                                        _c(
                                          "span",
                                          [
                                            _vm._v(
                                              "\n                     Teléfono: " +
                                                _vm._s(listItem.phone) +
                                                " "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "\n                     Correo: " +
                                                _vm._s(listItem.email) +
                                                " "
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _c(
                                              "vs-checkbox",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openSelectProjectType(
                                                      listItem
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    listItem.generate_process,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      listItem,
                                                      "generate_process",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "listItem.generate_process",
                                                },
                                              },
                                              [_vm._v("Crear proceso")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "vs-row",
                                        { attrs: { "vs-justify": "flex-end" } },
                                        [
                                          _c("vs-button", {
                                            attrs: {
                                              color: "primary",
                                              icon: "remove_red_eye",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openModalShowCandidate(
                                                  listItem
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vs-col",
        {
          attrs: {
            "vs-offset": "10",
            "vs-type": "flex",
            "vs-justify": "center",
            "vs-align": "center",
            "vs-w": "2",
          },
        },
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-5 block",
              attrs: { type: "filled" },
              on: { click: _vm.openModalCreateCandidate },
            },
            [_vm._v("Crear candidato")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }